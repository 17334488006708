@import url("//hello.myfonts.net/count/2c86e1");
body{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.Justifier {
    font-size: 5%;
    line-height: 0px;
    text-align: justify
}

.Justifier:after {
    display: inline-block;
    width: 100%;
    height: 0;
    content: ""
}

.jItem {
    display: inline-block;
    vertical-align: top;
    line-height: normal;
    font-size: 2000%
}

.text-left {
    text-align: left !important
}

.text-center {
    text-align: center !important
}

.text-right {
    text-align: right !important
}

.vAligner:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0px;
    background: yellow
}

.vItem {
    display: inline-block;
    vertical-align: middle
}

.listHorz {
    list-style: none
}

.listHorz>li {
    display: inline-block
}

.fillParent {
    width: 100%;
    height: 100%
}
.box{
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display:none;}
.fechadiv{    color: #FFF;
              text-decoration: none;
              background: #adc80a;
              padding: 5px 20px;}
.box:before{content:' '; height:100%; vertical-align: middle;}
.box360{font-family: PT Sans,Tahoma,sans-serif;
        vertical-align: middle;
        font-size: 4em;
        text-align: center;
        color: #FFF;
        background: url(../../img/bck.png) center center no-repeat;
        background-size: cover;
        width:100%;
        height:100%;
        display: inline-block;
}
.box360 > .dummy{height:100%;width:1px;vertical-align: middle}
.box360 > p{    vertical-align: middle;
                display: inline-block;
                width: 95%;position:relative;z-index:3;}
.box360 > .apaga{    position: absolute;
                     left: 0;
                     top: 0;
                     z-index: 0;
                     opacity: 0.3;}
.multiLayer .fillParent {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%
}

.floatLeft {
    float: left
}

.floatRight {
    float: right
}

.hidden {
    display: none !important
}

.invisible {
    opacity: 0 !important
}

.Site-icon {
    display: inline-block;
    background-image: url('../../img/sprite.png')
}

.icon-close,
.with-icon-close:before {
    width: 32px;
    height: 31px;
    background-position: 0px 0px
}

.with-icon-close:before {
    content: ""
}

.icon-map-hide,
.with-icon-map-hide:before {
    width: 28px;
    height: 15px;
    background-position: -34px 0px
}

.with-icon-map-hide:before {
    content: ""
}

.icon-map-show,
.with-icon-map-show:before {
    width: 28px;
    height: 15px;
    background-position: 0px -33px
}

.with-icon-map-show:before {
    content: ""
}

* {
    padding: 0;
    margin: 0;
    border: 0
}

html {
    height: 100%
}

body {
    position: relative;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 100%;
    padding-bottom: 77px;
    background-color: #000000;
    font-size: 10px
}

@media (max-width: 600px) {
    body {
        padding-bottom: 246px;
    }
}

.page {
    background: #1A1A1A url('../../img/inner_bck.jpg') 50% 0% no-repeat;
    text-align: center
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
time {
    display: block
}

iframe {
    border: none
}

ul {
    list-style-type: none
}

::-moz-selection {
    color: #ffffff;
    background: #adc80a
}

::selection {
    color: #ffffff;
    background: #adc80a
}

.title {
    margin: 20px 0 60px;
    color: #ffffff;
    font-family: 'Geometria-Medium', Arial, sans-serif;
    font-size: 48px;
    font-weight: 100;
    letter-spacing: .075em;
    text-transform: uppercase
}

.container h1 {
    position: relative;
    z-index: 10;
    font-size: 2.33em;
    letter-spacing: .1em;
    text-align: center
}

.container h2 {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 1em;
    color: #adc80a;
    font-family: 'PT Sans', Tahoma, sans-serif;
    font-size: 1.09em;
    font-weight: 400
}

.container a {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    text-decoration: none
}

.container a:hover {
    border: none
}

@media (max-width: 480px),
(max-height: 480px) {
    .title {
        font-size: 43px
    }
    .container h1 {
        font-size: 1.8em
    }
}

.fullScreen {
    height: 100%;
    width: 100%
}

.container {
    position: relative;
    z-index: 1;
    clear: both;
    display: block;
    max-width: 1200px;
    padding: 1.66em 0 0;
    margin: 0 auto;
    color: #ffffff;
    font: 24px/1.2 'PT Sans', Tahoma, sans-serif;
    text-align: left
}

@media (max-width: 1280px) {
    .container {
        padding-left: 2em;
        padding-right: 2em;
    }
}

@media (max-width: 950px) {
    .container {
        padding-left: 1em;
        padding-right: 1em;
    }
}

.full-width .container {
    max-width: none;
    padding-left: 0 !important;
    padding-right: 0 !important
}

.container:last-child {
    padding-bottom: 4em
}

@font-face {
    font-family: 'Geometria-Medium';
    src: url('../../fonts/geometria/2C86E1_0_0.eot');
    src: url('../../fonts/geometria/2C86E1_0_0.eot?#iefix') format('embedded-opentype'), url('../../fonts/geometria/2C86E1_0_0.woff2') format('woff2'), url('../../fonts/geometria/2C86E1_0_0.woff') format('woff'), url('../../fonts/geometria/2C86E1_0_0.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

.link {
    border-bottom: 1px solid;
    color: #ffffff;
    text-decoration: none
}

.link:hover {
    color: #adc80a
}

.link-st {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    margin-left: .66em;
    margin-right: .66em;
    border: none;
    color: #ffffff;
    font-size: 18px;
    text-transform: lowercase;
    text-decoration: none
}

.link-st:after {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 0;
    border-bottom: 2px solid #adc80a;
    content: "";
    -moz-transition: border .2s linear 0s, bottom .2s linear 0s;
    transition: border .2s linear 0s, bottom .2s linear 0s
}

.link-st:hover:after {
    bottom: 38%;
    border-width: 3px
}

.link-bg {
    position: relative;
    white-space: nowrap;
    border-bottom: 1px solid #adc80a;
    color: #ffffff;
    text-decoration: none
}

.link-bg:after {
    position: absolute;
    top: -.05em;
    right: -.4em;
    bottom: -.1em;
    left: -.4em;
    z-index: -1;
    background: #adc80a;
    border-bottom: 1px solid #adc80a;
    opacity: 0;
    -moz-transition: opacity 0.1s linear 0s;
    transition: opacity 0.1s linear 0s;
    content: ""
}

.link-bg:hover {
    border-color: transparent
}

.link-bg:hover:after {
    opacity: 1
}

.link-bg-block {
    display: inline-block;
    white-space: nowrap;
    margin: 0 .4em .16em;
    padding-top: .16em;
    border-bottom: 2px solid #adc80a;
    color: #ffffff;
    font-size: 18px;
    text-transform: lowercase;
    text-decoration: none
}

.link-bg-block:hover {
    margin: 0;
    padding: 0.16em 0.4em;
    background-color: #adc80a;
    border-color: transparent;
    color: #ffffff
}

.Map {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0 !important;
    z-index: 1000;
    font-size: 16px;
    display: none
}

.Map-info {
    position: absolute;
    top: 0;
    z-index: 10;
    display: table;
    height: 75%;
    width: 100%;
    background-color: #131313;
    font-family: 'Geometria-Medium', Arial, sans-serif;
    font-size: 1.125em;
    line-height: 1.33;
    text-align: left;
    -moz-transition: -moz-transform .3s ease 0s;
    transition: transform .3s ease 0s
}

@media (max-width: 360px),
(max-height: 480px) {
    .Map-info {
        font-size: 1em;
    }
}

.Map-info>.icon-close {
    position: absolute;
    top: 34px;
    right: 34px;
    z-index: 20;
    background-color: #131313;
    cursor: pointer;
    -moz-transition: -moz-transform 0.3s ease-out;
    transition: transform 0.3s ease-out
}

@media (max-width: 480px),
(max-height: 480px) {
    .Map-info>.icon-close {
        top: 14px;
        right: 14px;
    }
}

.Map-info>.icon-close:hover {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.Map-info-inner {
    display: table-cell;
    vertical-align: middle
}

.Map-contacts {
    max-width: 540px;
    margin: 1.1em auto 0;
    font-size: 1.2em
}

@media (max-width: 950px) {
    .Map-contacts {
        padding: 0 1em;
    }
}

@media (max-height: 480px) {
    .Map-contacts {
        margin-top: 0;
    }
}

.Map-contacts>p {
    padding: 0;
    margin: 0 0 .85em;
    color: white;
    font-size: inherit;
    font-family: 'PT Sans', Tahoma, sans-serif
}

@media (max-height: 650px) {
    .Map-contacts>p {
        margin-bottom: .4em;
    }
}

.Map-contacts>p.intro {
    margin-bottom: 2em
}

@media (max-height: 650px) {
    .Map-contacts>p.intro {
        margin-bottom: .8em;
    }
}

.Map-contacts .big-link {
    display: block;
    margin: .15em .37em
}

.Map-contacts .tel {
    white-space: nowrap
}

.Map-toggler {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 90px;
    height: 90px;
    background-color: #131313;
    cursor: pointer;
    -moz-transition: -moz-transform .3s ease 0s;
    transition: transform .3s ease 0s
}

@media (max-width: 480px),
(max-height: 480px) {
    .Map-toggler {
        width: 60px;
        height: 60px;
    }
}

.Map-toggler>.icon-map-hide,
.Map-toggler>.icon-map-show {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -7.5px 0 0 -14px
}

.Map-toggler>.icon-map-show {
    display: none
}

_:-o-prefocus,
body:last-child .Map-toggler {
    transition: none
}

.Map-info.slid {
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%)
}

.ie8minus .Map-info.slid {
    top: -50%
}

.Map-info.slid>.Map-toggler {
    -moz-transform: translateY(99%);
    -ms-transform: translateY(99%);
    transform: translateY(99%)
}

.ie8minus .Map-info.slid>.Map-toggler {
    top: 100%;
    bottom: auto
}

.Map-info.slid .icon-map-hide {
    display: none
}

.Map-info.slid .icon-map-show {
    display: block
}

#htdt-map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    background-color: #f07373 !important
}

.Footer {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 77px;
    color: #ffffff;
    font-family: 'PT Sans', Tahoma, sans-serif;
    font-size: 10px;
    font-style: normal;
    text-align: center
}

@media (max-width: 600px) {
    #intro h1{top:80%;font-size:10em;}
    .soci-back{display:none !important;}
    .Footer {
        height: 213px;
        padding-bottom: 30px;
        background: #151515;
    }
    .index-page .Footer {
        left: 10%;
        width: auto;
        background: transparent
    }
}

.index-page .Footer {
    position: fixed;
    bottom: -1px;
    opacity:0;
    -moz-transition:all 3s ease;
    transition:all 3s ease;
}

.portfolio-work .Footer {
    display: none
}

.text-page .Footer {
    background: #151515
}

.Footer-menu {
    list-style-type: none;
    overflow: hidden;
    height: 100%;
    margin: 0;
    text-align: justify
}

@media (max-width: 600px) {
    .Footer-menu {
        margin-left: 10%;
        min-width: 0;
        width: 155px;
        max-width: 700px;
        padding-top: 30px;
        height: 100%;
    }
    .index-page .Footer-menu {
        margin-left: 0
    }
}

.Footer-menu:after {
    display: inline-block;
    width: 100%;
    height: 0;
    content: ""
}

.Footer-menu>li {
    display: inline-block;
    vertical-align: middle;
}

@media (max-width: 600px) {
    .Footer-menu>li {
        display: block;
        text-align: left;
        min-width: 156px;
        background: #1d1d1d;
    }
}

.Footer-menu>li:first-child {
    height: 100%;
    padding-left: 7.7%
}

@media (max-width: 600px) {
    .Footer-menu>li:first-child {
        height: auto;
        padding-left: 0;
    }
}

.Footer-menu>li.last {
    padding-right: 7.7%
}

.Footer .link-st {
    margin-top: .23em;
    margin-bottom: .23em;
    font: 18px 'PT Sans', Tahoma, sans-serif;
    color: white
}

@media (max-width: 600px) {
    .Footer-logoWr {
        padding-left: 0%;
        margin-left: 5px;
        margin-bottom: 10px;
        height: auto;
    }
}

.index-page .Footer-logoWr {
    display: none
}

.Footer-logo {
    display: inline-block;
    vertical-align: middle;
    width: 23px;
    height: 23px;
    margin: 0 0 0 10px;
    padding: 0;
    background-color: #adc80a;
    border: medium none;
    border-radius: 100%;
    -moz-transition: -moz-transform .5s;
    transition: transform .5s
}

.Footer-logo:after {
    content: none
}

.Footer-logo:hover {
    border-radius: 0%;
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}